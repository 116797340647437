// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap");
require("daterangepicker");

import "../stylesheets/application";
const images = require.context('../images', true)

import "@fortawesome/fontawesome-free/js/all";

document.addEventListener("turbolinks:load", function() {
  $(function () {
      $('[data-toggle="tooltip"]').tooltip()
      $('[data-toggle="popover"]').popover()
  });

  $("#search").on("keyup", function() {
    var value = $(this).val().toLowerCase();
    $("table tbody tr").filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    });
  });

  // $('.generate-report').click(function() {
  //   alert($(this).attr('href'));
  //   $(this).attr("href", $(this).attr('href') + "?start_date=" + $('#start_date').val() + "&end_date=" + $('#end_date').val());
  // });

  $('#upload_csv').on('shown.bs.modal', function () {
    $('#upload_csv').trigger('focus')
  });

  $('.input-date-range').daterangepicker({
    locale: {
      format: 'DD-MM-YYYY',
      cancelLabel: 'Clear'
    },
    autoUpdateInput: true,
    autoApply: false,
    alwaysShowCalendars: true,
    ranges: {
       'Today':        [moment(), moment()],
       'Yesterday':    [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
       'Last 7 Days':  [moment().subtract(6, 'days'), moment()],
       'Last 30 Days': [moment().subtract(29, 'days'), moment()],
       'Last 90 Days': [moment().subtract(89, 'days'), moment()],
       'This Month':   [moment().startOf('month'), moment().endOf('month')],
       'Last Month':   [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }
  });
  $('.input-date-range').val('');
  $('.input-date-range').on('cancel.daterangepicker', function(ev, picker) {
    $('.input-date-range').val('');
  });

  $("#checkAll").click(function () {
    $('input:checkbox.edit_item').not(this).prop('checked', this.checked);
    var item_count = $("#checkAll").closest("form").find('.edit_item:checked').length;
    var is_submit_disabled = item_count > 0 ? false : true
    var button_text = item_count > 1 ? "Bulk Edit" : "Edit"

    $("#checkAll").closest("form").find("span.selected").text(item_count);
    $("#checkAll").closest("form").find("input[type=submit").prop('value', button_text);
    $("#checkAll").closest("form").find("input[type=submit").prop('disabled', is_submit_disabled);
  });

  $(".edit_item").click(function () {
    var item_count = $("#checkAll").closest("form").find('.edit_item:checked').length;
    var is_submit_disabled = item_count > 0 ? false : true
    var button_text = item_count > 1 ? "Bulk Edit" : "Edit"
    
    $("#checkAll").closest("form").find("span.selected").text(item_count);
    $("#checkAll").closest("form").find("input[type=submit").prop('value', button_text);
    $("#checkAll").closest("form").find("input[type=submit").prop('disabled', is_submit_disabled);
  });
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
